<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Editar Registro</h3>
            <h5 class="card-title font-weight-bolder mt-4 mb-2" style='font-size: 20px'>General</h5>
            <p style='font-size: 1rem'>Aquí puede introducir la información general que desea modificar del Movimiento,
              como la fecha, la
              moneda y la descripción.</p>
          </template>
          <form class='p-4' @submit.prevent='editarMovimiento'>
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label for="Fecha">Fecha *</label>
                <b-form-input type='date' v-model='EditarMovimiento.fecha' id="Fecha" name='Fecha'
                              aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Fecha"
                              required
                              trim></b-form-input>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="Divisa">Divisa *</label>
                <div class='d-flex'>
                  <b-form-select name='Divisa' v-model='EditarMovimiento.idDivisa'
                                 placeholder='-- Selecionar la Divisa --' :options="ListDivisa" required value='null'>
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Selecionar la Divisa --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <!-- <vs-button class='ml-2 m-0' icon @click="$bvModal.show('bv-modal-divisa')"><ion-icon
                      style='font-size: 1.3rem' name="add-outline"></ion-icon></vs-button> -->
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="Descripcion">Descripción *</label>
                <b-form-textarea id="Descripcion" v-model='EditarMovimiento.descripcion' name='Descripcion'
                                 aria-describedby="input-live-help input-live-feedback"
                                 placeholder="Ingrese Descripción" rows='6'
                                 required trim></b-form-textarea>
              </div>
            </div>
            <div class="pt-4">
              <h5 class="card-title font-weight-bolder mt-4 mb-2" style='font-size: 20px'>Movimientos</h5>
              <p style='font-size: 1rem'>Aquí puede introducir los Movimiento, como cuenta, cargo, abono, etc.</p>
              <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style='background: var(--body-bg) !important'>
                  <vs-th style='min-width: 270px !important;  background: var(--body-bg) !important'>
                    Cuenta
                  </vs-th>
                  <vs-th style='min-width: 280px !important;  background: var(--body-bg) !important'>
                    Nota
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    debito
                  </vs-th>
                  <vs-th style='min-width: 10rem;  background: var(--body-bg) !important'>
                    credito
                  </vs-th>
                  <vs-th style='min-width: 3rem;  background: var(--body-bg) !important'>
                    Opciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(movi, index) in movimientos" :key="index">
                  <vs-td style="z-index: 5 !important">
                    <div style="display: flex; align-items: center; width: 100%">
                      <b-form-select placeholder="Seleccione una medida favorita" value-field="ID"
                                     text-field="SubCuenta"
                                     :options="ListCuenta" v-model="movi.cuentaID">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Please select an option --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <vs-button v-b-tooltip.auto @click.prevent="abrirModalSeleccion(movi.cuentaID, index)"
                                 title='Buscar' success icon class=' '>
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </vs-button>
                    </div>
                  </vs-td>
                  <vs-td>
                    <b-form-input v-bind:name="`nota${index}`" v-model='movi.nota'
                                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese" required
                                  trim></b-form-input>
                  </vs-td>
                  <vs-td>
                    <b-form-input type="number" v-model="movi.debito" :disabled="movi.credito > 0" placeholder='0'
                                  v-bind:name="`Debito${index}`" min="1"></b-form-input>
                  </vs-td>
                  <vs-td>
                    <b-form-input type="number" v-model="movi.credito" :disabled="movi.debito > 0" placeholder='0'
                                  v-bind:name="`Credito${index}`" min="0" step="1"></b-form-input>
                  </vs-td>
                  <vs-td>
                    <vs-button class='bg-danger' @click="eliminarArticulo(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #notFound>
                <vs-tr class='d-flex justify-content-center'>
                  No hay Movimientos
                </vs-tr>
              </template>
              <template #footer>
                <div class='d-flex justify-content-center'>
                  <vs-button class='d-flex' style='gap: 1rem;' primary @click.prevent="agregarMovimiento">
                    <ion-icon
                        style='font-size: 1.6rem' name="add-outline"></ion-icon>
                    Agregar un Movimiento
                  </vs-button>
                </div>
              </template>
            </vs-table>
            <hr style="height: 1px; background: var(--dark)" class="w-100"/>
            <div v-for="item of ListaEvidencias" :key="item.id">
              {{ item.id }}
              <div class="d-flex justify-content-end">
                <vs-button @click.prevent="eliminarEvidencia(item)" class="d-flex " style="margin-bottom: -2.5rem;"
                           danger><i class="fa-solid fa-xmark p-0" style="font-size: 1.1rem;"></i></vs-button>
              </div>
              <DropFile :urlDir="`empresa/${persona.empresa.id}/evidencias/registrosContables/`+$route.params.id"
                        :pathGet="`RegistroContableEvidencias/ListRegistroContableEvidencias/${$route.params.id}`"
                        pathPost="RegistroContableEvidencias/CrearRegistroContableEvidencias" :injectData="{
                  NombreArchivo: 'rcontable',
                  Url: `https://helexiumfiles.com/empresa/${persona.empresa.id}/evidencias/registrosContables/`+$route.params.id,
                  RegistroContableId: $route.params.id,
                  Fecha: $moment
                    .tz(new Date(), 'America/Bogota')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                }" :fileTypes="[
                  'application/pdf',
                  'application/docx',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/vnd.ms-powerpoint',
                  'text/plain',
                  'image/*',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.ms-powerpoint',
                  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ]"/>


            </div>

            <div class="d-flex justify-content-center">
              <vs-button class="d-flex" primary @click.prevent="agregarEvidenciaALista()">
                <ion-icon
                    style="font-size: 1.6rem" name="add-outline"></ion-icon>
                Agregar evidencia
              </vs-button>
              <!-- boton temporal -->
              <!-- <vs-button class="d-flex mx-1" primary @click.prevent="saveData()"><ion-icon style="font-size: 1.6rem"
                  name="add-outline"></ion-icon> guardar</vs-button> -->
            </div>


            <!-- <hr style="height: 1px; background: var(--dark)" class="w-100" /> -->
            <hr style='height: 1px; background: var(--dark);' class='w-100'/>
            <div class="row pt-3 px-2 justify-content-end align-items-center">
              <h3 class='col-4 col-md-2 text-center'>Subtotal</h3>
              <p class='col-4 col-md-2 mb-0 py-2' style='font-size: 1.2rem;'>${{ Totaldebito }}</p>
              <p class='col-4 col-md-2 mb-0 py-2' style='font-size: 1.2rem;'>${{ Totalcredito }}</p>
            </div>
            <div class="row pt-0 px-2 justify-content-end align-items-center">
              <h3 class='col-2 py-3 text-center'>Total</h3>
              <template>
                <p v-if='Totaldebito === Totalcredito || Totaldebito > Totalcredito'
                   class='col-2 mb-0 bg-success rounded-left py-2 '
                   style='font-size: 1.2rem;'>$ {{ Totaldebito }}</p>
                <p v-if='Totaldebito < Totalcredito' class='col-2 mb-0 bg-danger py-2 rounded-left'
                   style='font-size: 1.2rem;'>$ {{
                    Totaldebito - Totalcredito
                  }}</p>
              </template>
              <template>
                <p v-if='Totaldebito === Totalcredito || Totalcredito > Totaldebito'
                   class='col-2 mb-0 bg-success rounded-right py-2'
                   style='font-size: 1.2rem;'>$ {{ Totalcredito }}</p>
                <p v-if='Totalcredito < Totaldebito' class='col-2 mb-0 bg-danger py-2 rounded-right'
                   style='font-size: 1.2rem;'>$ {{
                    Totalcredito - Totaldebito
                  }}</p>
              </template>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" danger
                         @click.prevent="$router.push('/helex/Erp/Contabilidad/RegistrosContables/Registros')">Cancelar
              </vs-button>
              <vs-button size="large" :loading="waiting"
                         :disabled='movimientos.length <= 1 || Totalcredito !== Totaldebito'>Guardar
              </vs-button>
            </div>
          </form>
          <b-modal id="bv-modal-divisa" hide-footer>
            <template #modal-title>
              <h4>Nueva Divisa</h4>
            </template>
            <form>
              <div>
                <label clas for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Nombre" required trim></b-form-input>
              </div>
              <div class='mt-3'>
                <label clas for="Codigo">Código *</label>
                <b-form-input id="Codigo" name='Codigo' aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Código" required trim></b-form-input>
              </div>
              <div class='mt-3'>
                <label clas for="Cotizacion">Cotización *</label>
                <b-form-input id="Cotizacion" name='Cotizacion' aria-describedby="input-live-help input-live-feedback"
                              placeholder="Ingrese Cotización" required trim></b-form-input>
              </div>
              <div class='d-flex mt-4 justify-content-end'>
                <vs-button size="large" danger @click="$bvModal.hide('bv-modal-divisa')">Cancelar</vs-button>
                <vs-button size="large">Guardar</vs-button>
              </div>
            </form>
          </b-modal>
          <vs-dialog v-model="ModalCuentas" title="Buscar Cuenta" :close-on-backdrop="false"
                     :close-on-esc="false" width="80%">
            <template #header>
              <h4 class="not-margin">Cuentas</h4>
            </template>
            <div>
              <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :seleccion-simple="true" :columns="columns"
                              key-seleccion="Id" @KeySelectionSimpleRow="CuentaSeleccionManual = $event"
                              @click.native.stop>
                <template v-slot:defecto="{ row }">
                  {{ row.defecto ? 'Defecto' : 'Creada' }}
                </template>
              </DataTableLotes>
            </div>
            <template #footer>
              <div style="display: flex; justify-content: start ">
                <vs-button type="filled" @click="seleccionarCuentaTabla" color="primary">Aceptar</vs-button>
                <vs-button @click="ModalCuentas = false" type="filled" color="danger">Cerrar</vs-button>
              </div>
            </template>
          </vs-dialog>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {eventBus} from "../../../../../../main";
import DropFile from "@/components/helexium/DropFileV3.vue";

import Swal from 'sweetalert2';
import {core} from '../../../../../../config/pluginInit';
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";

export default {
  components: {
    DataTableLotes,
    DropFile,
  },
  data: () => ({
    ListaEvidencias: [],
    waiting: false,
    movimientos: [],
    ListDivisa: [],
    ListCuenta: [],
    EditarMovimiento: {
      fecha: null,
      idDivisa: null,
      descripcion: null,
      personaId: null,
      cuentasMovimiento: [],
      persona:{},
    },
    PersonaId:null,
    columns: [
      {label: "Codigo", key: "CodigoCompleto", sortable: true},
      {label: "Nombre", key: "NombreSubCuenta", sortable: true},
      {label: "Clase", key: "NombreClase", sortable: true},
      {label: "Grupo", key: "nombreGrupo", sortable: true},
      {label: "Cuenta", key: "nombreCuenta", sortable: true},
      {label: "Tipo", key: "defecto", sortable: true},
    ],
    ModalCuentas: false,
    CuentaSeleccionManual: null,
    indexSeleccion: null,
  }),
  async mounted() {
    const Persona = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.EditarMovimiento.personaId = Persona.id;
    this.persona = Persona
    this.PersonaId = Persona.id;
    core.index();
    await this.getData();
  },
  computed: {
    Totaldebito() {
      return this.movimientos.reduce((acc, movi) => acc + parseFloat(movi.debito), 0) || 0;
    },
    Totalcredito() {
      return this.movimientos.reduce((acc, movi) => acc + parseFloat(movi.credito), 0) || 0;
    }
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.PersonaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;
      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `DESubCuenta/consultarCantidadSubCuentasEmpresa/${this.PersonaId}?tipo=&filtro=${filtro}&All=true`,
        }),
        this.$store.dispatch('hl_post', {
          path: `DESubCuenta/ObtenerSubCuentasEmpresa/${this.PersonaId}?tipo=&All=true`,
          data: params
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
        }
      });
      return {total, data};
    },
    guardarEvidencias() {
      // console.log('en savedata registro')
      eventBus.$emit("GuardandoEvidenciaRegistroCrear", true);
    },
    agregarEvidenciaALista() {
      this.ListaEvidencias.push({id: this.ListaEvidencias.length + 1})
    },
    eliminarEvidencia(item) {
      let posicionItem = this.ListaEvidencias.indexOf(item)
      this.ListaEvidencias.splice(posicionItem, 1)
      // this.$forceUpdate()
    },
    eliminarArticulo(index) {
      this.movimientos.splice(index, 1);
    },
    agregarMovimiento() {
      this.movimientos.push({
        idMovimientoSubCuenta: null,
        cuentaID: null,
        nota: '',
        debito: 0,
        credito: 0
      });
    },
    async getData() {
      await this.$store.dispatch('hl_get', {
        path: `DEMovimientos/ObtenerMovimientoId/${this.$route.params.id}`,
      }).then(response => {
        response.data.cuentasMovimiento.map(mov => {
          this.movimientos.push({
            idMovimientoSubCuenta: mov.idMovimientoCuenta,
            cuentaID: mov.idSubCuenta,
            cuenta: mov.NombreSubCuenta,
            nota: mov.nota,
            debito: mov.debito,
            credito: mov.credito
          })
        })
        this.EditarMovimiento.fecha = this.$moment.tz(response.data.fecha, "America/Bogota").format("YYYY-MM-DD")
        this.EditarMovimiento.idDivisa = response.data.divisas.id;
        this.EditarMovimiento.descripcion = response.data.descripcion;
      })

      await this.$store.dispatch("hl_get", {
        path: `GDivisas/ObtenerDivisas`,
      }).then(res => {
        if (res.data.length > 0) {
          this.ListDivisa = res.data.map((item) => ({
            value: item.id,
            text: `${item.codigo} - ${item.nombre}`
          }));
        } else {
          this.ListDivisa = [];
        }
      })
      await this.$store.dispatch("hl_get", {
        path: `DESubCuenta/ObtenerInformacionCuentas/${this.EditarMovimiento.personaId}`,
      }).then(res => {
        if (res.respuesta) {
          this.ListCuenta = res.data ?? [];
          this.movimientos.forEach(item => {
            if (!this.ListCuenta.some(cuenta => cuenta.ID === item.cuentaID)) {
              this.ListCuenta.push({
                ID: item.cuentaID,
                SubCuenta: item.cuenta
              });
            }
          });

        } else {
          this.ListCuenta = [];
        }
      })
    },
    async editarMovimiento(event) {
      const data = Object.fromEntries(new FormData(event.target).entries());

      if (data.Fecha === '' || data.Divisa === '' || data.Descripcion === '') {
        this.error = 'Todos los campos son obligatorios';
        return;
      }

      this.EditarMovimiento.fecha = this.$moment.tz(data.Fecha, "America/Bogota").format("YYYY-MM-DDTHH:mm")
      this.EditarMovimiento.idDivisa = data.Divisa;
      this.EditarMovimiento.descripcion = data.Descripcion;
      this.EditarMovimiento.cuentasMovimiento = this.movimientos;

      this.EditarMovimiento.cuentasMovimiento.map(item => {
        item.debito = parseFloat(item.debito);
        item.credito = parseFloat(item.credito);
      });

      try {
        this.waiting = true;
        const response = await this.$store.getters.fetchPost({
          path: `DEMovimientos/ActualizarMovimiento/${this.$route.params.id}`,
          data: this.EditarMovimiento
        })
        if (response.ok) {
          await this.guardarEvidencias()
          Swal.fire({
            title: 'Movimiento Editado',
            text: 'Se ha editado el Movimiento',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.$router.push('/helex/Erp/Contabilidad/RegistrosContables/Registros');
            event.target.reset();
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error al editar el Movimiento',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      } catch (error) {
        console.log("erro al editar el movimiento", error);
      } finally {
        this.waiting = false;
      }
    },
    abrirModalSeleccion(row, index) {
      this.CuentaSeleccionManual = null;
      this.ModalCuentas = true;
      this.indexSeleccion = index;
      this.$nextTick(() => {
        if (this.$refs.dataTableLotes) {
          this.$refs.dataTableLotes.$emit('EstablecesDatoSeleccionadoSimple', row);
        }
      });
    },
    seleccionarCuentaTabla() {
      if (!this.CuentaSeleccionManual) {
        return;
      }
      this.ListCuenta.push({
        ID: this.CuentaSeleccionManual.Id,
        SubCuenta: this.CuentaSeleccionManual.CodigoCompleto+" "+this.CuentaSeleccionManual.NombreSubCuenta,
      });
      this.movimientos[this.indexSeleccion].cuentaID = this.CuentaSeleccionManual.Id;
      this.ModalCuentas = false;
    },
  }
}
</script>

<style>
.vs-table__footer {
  background: var(--body-bg) !important;
}
</style>

<style>
.vs__dropdown-toggle {
  min-height: 2.2rem;
  height: auto;
}
</style>
